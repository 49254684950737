import React, { Component } from "react"

const getYoutubeId = url => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\\&v=)([^#\\&\\?]*).*/
  const match = url.match(regExp)
  if (match && match[2].length === 11) {
    return match[2]
  }
  return 'error'
}

const getYoutubeEmbedLink = youtubeLink => {
  const id = getYoutubeId(youtubeLink)
  return `//www.youtube.com/embed/${id}`
}
class Video extends Component {
  componentDidMount() {
    if (!this.props.youtubeVideoId) {
      let wistiaScript = document.querySelector(
        "script[src='https://fast.wistia.com/assets/external/E-v1.js']"
      )
      if (!wistiaScript) {
        wistiaScript = document.createElement("script")
        wistiaScript.src = "https://fast.wistia.com/assets/external/E-v1.js"
        wistiaScript.async = true
        document.body.appendChild(wistiaScript)
      }
  
      let videoScript = document.querySelector(
        `script[src='https://fast.wistia.com/embed/medias/${this.props.videoId}.jsonp']`
      )
      if (!videoScript) {
        const videoScript = document.createElement("script")
        videoScript.src = `https://fast.wistia.com/embed/medias/${this.props.videoId}.jsonp`
        videoScript.async = true
        document.body.appendChild(videoScript)
      }
    }
  }

  render() {
    return (
      this.props.youtubeVideoId ? (
        <div style={{ textAlign: 'center' }}>
          <iframe width="560" style={{ maxWidth: '100%' }} height="315" src={getYoutubeEmbedLink(this.props.youtubeVideoId)} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
      ) : (
        <div className={this.props.className} style={this.props.style}>
          <div
            className="wistia_responsive_padding"
            style={{ padding: "56.25% 0 0 0", position: "relative" }}
          >
            <div
              className="wistia_responsive_wrapper"
              style={{
                height: "100%",
                left: "0",
                position: "absolute",
                top: "0",
                width: "100%",
              }}
            >
              <div
                className={`wistia_embed wistia_async_${this.props.videoId} ${
                  this.props.popup ? "autoPlay=true" : ""
                }`}
                style={{ height: "100%", width: "100%" }}
              >
                &nbsp;
                <div
                  className="wistia_swatch"
                  style={{
                    height: "100%",
                    left: 0,
                    opacity: 1,
                    overflow: "hidden",
                    position: "absolute",
                    top: 0,
                    transition: "opacity 200ms",
                    width: "100%",
                  }}
                >
                  <img
                    src={`https://fast.wistia.com/embed/medias/${this.props.videoId}/swatch`}
                    style={{
                      filter: "blur(5px)",
                      height: "100%",
                      objectFit: "contain",
                      width: "100%",
                    }}
                    alt="video placeholder"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    )

    // Previous embed code
    // return (
    //   <div
    //     className={this.props.className}
    //     style={this.props.style}
    //     dangerouslySetInnerHTML={{
    //       __html: `<div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><div class="wistia_embed wistia_async_${
    //         this.props.videoId
    //       } videoFoam=true ${
    //         this.props.popup ? "autoPlay=true" : ""
    //       }" style="height:100%;position:relative;width:100%"><div class="wistia_swatch" style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"><img src="https://fast.wistia.com/embed/medias/${
    //         this.props.videoId
    //       }/swatch" style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" alt="" aria-hidden="true" onload="this.parentNode.style.opacity=1;" /></div></div></div></div>`,
    //     }}
    //   />
    // )
  }
}

export default Video
