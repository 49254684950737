import React from "react"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Video from "components/video"
import ContentfulLink from "components/contentfulLink"

import styles from "styles/components/rich-text.module.scss"
import QuotesArticle from "images/icons/quotes-article.svg"

import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const Quote = ({ quote, author, position }) => {
  if (!quote && !author && !position) return false
  return (
    <blockquote className={styles.quote}>
      <QuotesArticle className={styles.icon} />
      <p className={styles.quote}>{quote}</p>
      <p className={styles.author}>{author}</p>
      <p className={styles.position}>{position}</p>
    </blockquote>
  )
}

const ArticleLink = (props) => {
  const { inline } = props
  return (
    <ContentfulLink
      className={cx("link", { inline })}
      ctaClassName={styles.cta}
      {...props}
    >
      {props.linkText}
    </ContentfulLink>
  )
}

const RichText = ({ text, images }) => {
  const options = {
    renderText: (text) => {
      // turns all "/n" into <br/>
      return text.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment]
      }, [])
    },
    renderNode: {
      [BLOCKS.QUOTE]: (node) => {
        let quote
        let author
        let position
        // blockquote content needs to be very specific
        // first paragraph = quote
        // second paragraph = author /n position
        if (node.content.length === 2) {
          quote = node.content.slice(0, 1)[0]?.content[0]?.value
          let source = node.content.slice(1)[0]
          let firstContent = source.content[0].value
          let i = 0

          //allow for extra newlines
          while (firstContent === "\n" && i < source.content.length) {
            i++
            firstContent = source.content[i].value
          }

          author = firstContent
          position = source.content[i + 1]?.value
        }
        return <Quote quote={quote} author={author} position={position} />
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        if (images && node.data.target.fields) {
          const { title, description, file } = node.data.target.fields
          // image.src has a url param that we need to strip off to match file.url
          const image = images.find(image => (image?.fluid?.src || '').split("?")[0] === (file && file["en-US"]?.url))
          if (image?.file?.url) {
            return (
              <figure>
                <img
                  title={title["en-US"]}
                  src={image?.file?.url}
                  className={cx('customImage')}
                  alt={title["en-US"]}
                />
                {description && <figcaption>{description["en-US"]}</figcaption>}
              </figure>
            )
          }
        }
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        if (node.data.target.fields) {
          const contentType = node.data.target.sys.contentType.sys.contentful_id
          const fields = node.data.target.fields
          const props = {}
          Object.keys(fields).forEach(
            (key) => (props[key] = fields[key]["en-US"])
          )
          switch (contentType) {
            case "link":
              return <ArticleLink {...props} />
            case "video":
              return <Video {...props} className={styles.video} />
            case "textSection":
              if (props.markdown.includes("iframe")) {
                return (
                  <div
                    className={cx("embed", "iframe")}
                    dangerouslySetInnerHTML={{ __html: props.markdown }}
                  />
                )
              }
              return (
                <div
                  className={styles.embed}
                  dangerouslySetInnerHTML={{ __html: props.markdown }}
                />
              )
            default:
              return null
          }
        }
      },
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        if (node.data.target.fields) {
          const contentType = node.data.target.sys.contentType.sys.contentful_id
          const fields = node.data.target.fields
          const props = {}
          Object.keys(fields).forEach(
            (key) => (props[key] = fields[key]["en-US"])
          )
          switch (contentType) {
            case "link":
              return <ArticleLink {...props} inline />
            default:
              return null
          }
        }
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        )
      },
    },
  }

  return (
    <div className={styles.text}>
      {text && documentToReactComponents(text, options)}
    </div>
  )
}

export default RichText
