import React from "react"
import LoaddingRoller from "components/loaddingRoller"

// Need to use this to allow form callbacks to work
// https://community.hubspot.com/t5/APIs-Integrations/Form-callback-throws-unrelated-jquery-error/m-p/232121
if (typeof window !== "undefined") {
  window.jQuery =
    window.jQuery ||
    (() => ({
      // these are all methods required by HubSpot
      change: () => {},
      trigger: () => {},
    }))
}

// https://developers.hubspot.com/docs/methods/forms/advanced_form_options
class Form extends React.Component {
  componentDidMount() {
    // create Mutation Observer to style the form in js once it's loaded into the DOM
    // might be overkill lol
    const targetNode = document.getElementsByClassName(this.props.formTarget)[0]
    if (targetNode) {
      const config = { attributes: false, childList: true, subtree: false }
      this.observer = new MutationObserver((mutationsList) => {
        if (mutationsList[0].addedNodes) this.styleForm()
      })
      // Start observing the target node for configured mutations
      this.observer.observe(targetNode, config)
    }

    let hubspotScript = document.querySelector(
      "script[src='https://js.hsforms.net/forms/v2.js']"
    )

    if (!hubspotScript) {
      hubspotScript = document.createElement("script")
      hubspotScript.src = "https://js.hsforms.net/forms/v2.js"
      document.body.appendChild(hubspotScript)
    }

    hubspotScript.addEventListener("load", () => {
      this.createForm()
    })
    this.createForm()

    window.addEventListener("resize", () => {
      this.styleForm()
    })
  }

  createForm() {
    if (window.hbspt) {
      window.hbspt.forms.create({
        portalId: this.props.portalId,
        formId: this.props.formId,
        target: `.${this.props.formTarget}`,
        onFormReady: () => {
          const iframe = document.querySelector(`.${this.props.formTarget} .hs-form-iframe`)
          if (iframe) {
            const body = iframe.contentWindow.document.head;
            fetch('/style/hs-form.css')
              .then(response => response.text())
              .then(text => {
                const styleEl = body.querySelectorAll("style");
                styleEl.forEach(element => {
                  element.remove()
                });
                const styleE = document.createElement("style");
                styleE.innerHTML = text;
                body.appendChild(styleE);
              });
            const link = document.createElement("link");
            link.href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap"
            link.rel="stylesheet"
            body.appendChild(link);
          }
        },
        onFormSubmitted: () => {
          if (typeof this.props.onSubmit === 'function') {
            this.props.onSubmit()
          }
        },
      })
    }
  }

  componentWillUnmount() {
    if (typeof this.observer?.disconnect === 'function') {
      this.observer.disconnect()
    }
  }

  styleForm() {
    // Sets an explicit height for each fieldset element.
    // This allows their inner divs to have the same height and apply flexbox
    // properties (.hs-form-field) to get them to align.
    // This is commented out to fix the fieldset height being too high on mobile.
    // const fieldsets = document.querySelectorAll("fieldset")
    // fieldsets.forEach((fieldset) => {
    //   fieldset.style.minHeight = `${fieldset.clientHeight}px`
    // })
  }

  render() {
    return <div className={this.props.formTarget} >
      {this.props.showLoadding && <LoaddingRoller />}
    </div>
  }
}

export default Form
