import React from "react"
import styles from "styles/components/section-header.module.scss"
import "styles/animations.scss"

import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const PageHeader = ({
  label,
  title,
  subtext,
  innerHTML,
  centered,
  white,
  maxWidthTitle,
  maxWidthSubtext,
  titleClass,
  margin
}) => {
  return (
    <div
      className={cx(
        "sectionHeader",
        "hero",
        { centered: centered },
        { white: white }
      )}
    >
      {label && <p className={cx("sectionLabel", "delay")}>{label}</p>}
      {title && (
        <h1
          className={[cx("sectionTitle", "delay", { white: white }), titleClass].filter(Boolean).join(' ')}
          style={{ maxWidth: maxWidthTitle, margin: margin }}
        >
          {title}
        </h1>
      )}
      {subtext && (
        <p
          className={cx("subtext", "delay")}
          style={{ maxWidth: maxWidthSubtext }}
        >
          {subtext}
        </p>
      )}
      {innerHTML && (
        <div
          className={cx("subtext", "innerHTML", "delay")}
          style={{ maxWidth: maxWidthSubtext }}
          dangerouslySetInnerHTML={{ __html: innerHTML }}
        />
      )}
    </div>
  )
}

export default PageHeader
