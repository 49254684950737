import React from "react"

import Form from "components/form"
import SectionHeader from "components/sectionHeader"

import styles from "styles/components/form-block.module.scss"

const FormBlock = (props) => {
  const { title, subtext, label, style, onSubmit } = props
  return (
    <div className={styles.container} style={style}>
      <div className={styles.textContainer}>
        <SectionHeader label={label} title={title} subtext={subtext?.subtext} />
      </div>
      <div className={styles.formContainer}>
        <Form {...props} onSubmit={onSubmit} />
      </div>
    </div>
  )
}

export default FormBlock
