import React from "react"
import styles from "styles/components/loadding-roller.module.scss"
import classNames from "classnames/bind"

const cx = classNames.bind(styles)

const LoaddingRoller = () => {
  return <div className={cx('d-flex', 'justify-center') }>
    <div className={styles.ldsRoller}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
  </div>
}
export default LoaddingRoller
