import React from "react"
import Form from "components/form"
import styles from "styles/components/form-block-small.module.scss"
import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const FormBlockSmall = (props) => {
  const { title, subtext, centered, gray, btn } = props
  return (
    <div className={cx("container", gray ? "gray" : "white", { centered})}>
      {title && <p className={styles.title}>{title}</p>}
      {subtext && <p>{subtext.subtext ? subtext.subtext : ""}</p>}
      <div className={cx(styles.formContainer, btn ? "gray-form": "")}>
        <Form {...props} />
      </div>
    </div>
  )
}

export default FormBlockSmall
